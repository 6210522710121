import React, { useState } from 'react';

import { connect, ConnectedProps } from 'react-redux';

import IconUpload from '@mui/icons-material/Publish';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Tab, Tabs, IconButton } from '@mui/material';
import { bindActionCreators } from 'redux';
import fileService from 'services/file-service';
import { useAppDispatch } from 'store';
import { addFileUploadAction } from 'store/actions/file-upload-actions';
import { showModal } from 'store/actions/modal-actions';
import {CleanSiteFilesDTO, ResourceAccessNode} from 'types';
import styles from './add-file-upload-styles.module.scss';
import { windowsOs, selectedOsValue, selectedProxyValue } from 'components/shared/os-selection/os-selection';
import BrowserUploadTab from './browser-upload-tab/browser-upload-tab';
import CommandLineUploadTab from './command-line-upload-tab/command-line-upload-tab';
import DeleteUserIcon from "@mui/icons-material/Delete";
import {Alert} from "@mui/lab";
import ConfirmDialog from 'components/shared/confirm-dialog/confirm-dialog';

const mapDispatch = (dispatch) => bindActionCreators(
  {
    addFileUploadAction,
  },
  dispatch
);

const connector = connect(null, mapDispatch);

type ReduxProps = ConnectedProps<typeof connector>;

type OwnProps = {
  site?: ResourceAccessNode,
  project?: ResourceAccessNode
};

type Props = ReduxProps & OwnProps;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

// Warning : TabPanel is a component
// Never declare a component inside another component or it will provoke bugs 
// (the component is recreated at each render cycle and lose state)
export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  //tab is displayed if selectedTab = index of tab
  return (
    <div role='tabpanel'
         hidden={value !== index}
         id={`simple-tabpanel-${index}`}
         aria-labelledby={`simple-tab-${index}`}
         className={styles.fileInputContainer}
         {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const FileUploadDialog = ({ site, project, addFileUploadAction }: Props) => {
  
  const pathSeparator = ';';

  const [filePath, setFilePath] = useState('');
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const [tabSelected, setTabSelected] = useState(0);
  const [sasTokenLifetimeInHours, setSasTokenLifetimeInHours] = useState(12);
  const [bandwidthInMB, setBandwidthInMB] = useState(0);
  const [hasErrors, setHasErrors] = useState(false);
  const [htmlErrorMessage, setHtmlErrorMessage] = useState('');  
  
  const dispatch = useAppDispatch();
  const hideDialog = () => dispatch(showModal(undefined));

  const handleClose = () => {
    hideDialog();
  };

  const handleSelectedFile = (file: File) => {
    setSelectedFile(file);
  };

  const handleFilePath = (event) => {
    setFilePath(event.target.value);
  };

  const handleSasTokenLifetimeChange = (event, newValue) => {
    setSasTokenLifetimeInHours(newValue);
  };

  const handleBandwithChange = (event, newValue) => {
    setBandwidthInMB(newValue);
  };

  const validateFilePaths = (filePaths: string[]): boolean => {
    let isValidPaths = false;
    const filePathsWithError: string[] = [];
    let errorMessage = '';

    if (filePaths && filePaths.length > 0) {
      let regexPath: RegExp;
      if (selectedOsValue === windowsOs) {
        regexPath = new RegExp('^([a-zA-Z]:\\\\){1}([^<>:"|?*/]+)+\\\\?$');
      } else {
        regexPath = new RegExp('^(\\/[^/]+)+\\/?$');
      }

      filePaths.forEach(sourcePath => {
        if (!regexPath.test(sourcePath)) {
          filePathsWithError.push(sourcePath);
        }
      });

      if (filePathsWithError.length === 0) {
        isValidPaths = true;
      }

      if (!isValidPaths) {
        errorMessage = 'At least one file or directory path is invalid <br/> Invalid paths: <br/>';
        filePathsWithError.forEach(pathWithError => {
          errorMessage = errorMessage + `- "${pathWithError}" <br/>`;
        });
      }
    } else {
      errorMessage = 'No file or directory path <br/>';
    }

    setHasErrors(!isValidPaths);
    setHtmlErrorMessage(errorMessage);
    console.info('IsValidPaths=' + isValidPaths);

    return isValidPaths;
  };

  const cleanFileOrDirectoryPaths = (fileOrDirectoryPaths: string): string => {
    let cleanedPaths = fileOrDirectoryPaths;
    if (fileOrDirectoryPaths && fileOrDirectoryPaths !== '') {
      cleanedPaths = fileOrDirectoryPaths.trim();
      // Remove all non-printable characters
      cleanedPaths = cleanedPaths.replace(/\p{C}/gu, '');
      if (cleanedPaths.endsWith(pathSeparator)) {
        // Remove useless ';'
        cleanedPaths = cleanedPaths.slice(0, -1);
      }
    }
    return cleanedPaths;
  };

  const preUpload = () => {
    const uploadId = Math.random().toString(36).substr(2, 9);
    const siteId = site == null ? -1 : site.resourceId;
    const projectId = project == null ? -1 : project.resourceId;

    //if file selected and active tab is browser
    if (selectedFile && tabSelected === 0) {
      addFileUploadAction(siteId, projectId, uploadId, selectedFile);
      handleClose();
    } else if (tabSelected === 1) {
      // clean file path and then transform it into an array
      let filePaths: string[] = [];
      if (filePath && filePath !== '') {
        const filePathCleaned = cleanFileOrDirectoryPaths(filePath);
        filePaths = filePathCleaned.split(pathSeparator);
        filePaths = filePaths.map(sourcePath => sourcePath.trim());
      }
      const isValidFilePaths = validateFilePaths(filePaths);
      if (isValidFilePaths) {
        fileService.uploadFileWithCommandLine(siteId, projectId, filePaths, selectedOsValue, selectedProxyValue, sasTokenLifetimeInHours, bandwidthInMB);
        handleClose();
      }
    }
  };

  const handleChange = (event, newValue) => {
    setTabSelected(newValue);
  };

  const cleanSiteFiles = () => {
    const cleanSiteFilesDTO: CleanSiteFilesDTO = {
      isImport: true,
      siteId: site ? site.resourceId : -1,
      projectId: project ? project.resourceId : -1,
    };
    fileService.cleanSiteFiles(cleanSiteFilesDTO);
  };

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      fullWidth maxWidth='sm'
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'>
      <DialogTitle className={styles.dialogTitle}>
        <IconUpload className={styles.icon}/>
        <div className={styles.addFileTitle}>Upload file</div>
      </DialogTitle>

      <DialogContent className={styles.dialogSubTitle}>
        <DialogContentText className={styles.resourceName}>
          {site != null && (
              <>
                <b>Site</b>: {site.label}
              </>
          )}

          {(project != null) && (
              <>
                <b>Project</b>: {project.name}
              </>
          )}

        </DialogContentText>
        <div className='hidden'>
          <ConfirmDialog
              title='Clear my import directory'
              text='Are you sure you want to clear all import directory files?'
              isMenuItem={false}
              onConfirm={async () => cleanSiteFiles()}>
            <Button className='menu-button-mail'>
              <IconButton className={styles.delete_button__icon_button}>
                <DeleteUserIcon className={styles.delete_button__action_icon}/>
              </IconButton>
              <span className='menu-button-text-mail'>Clear my import directory</span>
            </Button>
          </ConfirmDialog>
        </div>
      </DialogContent>
      <Tabs value={tabSelected} onChange={handleChange} variant='fullWidth' centered>
        <Tab label='Browser'/>
        <Tab label='Command line'/>
      </Tabs>
      <TabPanel value={tabSelected} index={0}>
        <BrowserUploadTab selectedFile={selectedFile} handleSelectedFile={handleSelectedFile}/>
      </TabPanel>
      <TabPanel value={tabSelected} index={1}>
        <CommandLineUploadTab 
                filePath={filePath} 
                handleFilePath={handleFilePath} 
                hasErrors={hasErrors} 
                htmlErrorMessage={htmlErrorMessage}
                sasTokenLifetimeInHours={sasTokenLifetimeInHours}
                handleSasTokenLifetimeChange={handleSasTokenLifetimeChange}
                bandwidthInMB={bandwidthInMB}
                handleBandwithChange={handleBandwithChange}/>
      </TabPanel>
      <Alert severity="warning" className={styles.disclaimerMessage}>
        The data you are about to upload must be eligible for use in this SAAS environment.<br/>
        This environment is hosted in a cloud provider, in this case, Microsoft Azure.<br/>
        If any doubt, please refer to Totalenergies policy by contacting: ep.sismage-saas@totalenergies.com
      </Alert>
      <DialogActions>
        <Grid container alignItems='stretch' direction='row' justifyContent='flex-end'
              className={styles.accessAttributionButtonGrid}>
          <Grid item>
            <Button onClick={handleClose} className={styles.cancelButton}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={preUpload} variant='contained' color='primary' className={styles.validationButton} disabled={false}>
              UPLOAD
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default connector(FileUploadDialog);
